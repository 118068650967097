.facet {
  display: flex;
  flex-direction: column;
  width: 100%;


    .facet-body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 2px;
      height: 60px;
      background-color: #FFFFFF;
      border: 2px solid #dfdfdf;
      border-radius: 6px;
      color: #247dc0;
      cursor: pointer;
      padding: 8px 6px 6px 8px;

      .arrow-image {
        background: no-repeat right url('/assets/arrow-right-blue.png');
        height: 18px;
      }

      .facet-label {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .clear-btn {
          display: inline-block;
          cursor: pointer;
          width: 16px;
          height: 15px;
        }
      }

      .facet-selected-option {
        display: flex;
        gap: 4px;
        align-items: center;

        .check-mark {
          display: inline-block;
          width: 14px;
          height: 13px;
        }

        .desc-txt {
          display: inline-block;
          font-size: 10px;
          font-weight: 100;
        }
      }
    }

    .facet-dropdown, {
      display: flex;
      flex-direction: column;
      padding: 1px;
      color: #525252;
      background-image: url(/assets/facet-dropdown-background.png);
      background-position: 50% 100%;
      border-radius: 6px;

      .facet-radio-button {
        display: flex;
        align-items: center;
        font-size: 12px;
        gap: 6px;
        cursor: pointer;
        padding: 6px 16px;
        margin: 1px;

        &:hover {
          border: 1px dashed #0072bb;;
          border-radius: 4px;
          margin: 0;
        }

        .radio-button {
          display: inline-block;
          height: 12px;
          width: 12px;
          margin: 0;
        }
      }
    }
  &.facet-expanded,
    &.facet-selected {
      .facet-body {
        background-color: #0072bb;
        color: #FFF;
        border-color: #024976;

        .arrow-image {
          background: no-repeat right url('/assets/arrow-down-white.png');
        }
      }
    }
  }

