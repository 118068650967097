.app {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  background-color: #FFF;
  margin: 0 auto;

  @media print {
    max-width: 100%;
    margin: 0;
    print-color-adjust: exact;
  }

  .top-nav {
    display: flex;
    flex: auto;
    gap: 6px;
    justify-content: space-between;
    padding: 15px 25px 33px;

    .left-side {
      display: flex;
      gap: 30px;
      align-items: flex-end;
      overflow-x: hidden;

      .logo-link {
        .logo-image {
          display: inline-flex;
          max-width: 166px;
          width: 100%;
          min-width: 80px;
        }
      }

      .top-header {
        display: inline-block;
        overflow-x: hidden;
        text-overflow: ellipsis;
        font-size: 27px;
        font-weight: normal;
        color: #333;
      }
    }

    .right-side {
      display: flex;
      gap: 10px;
      align-items: flex-end;


      @media print {
        display: none;
      }

      .nav-icon {
        display: inline-block;
        width: 37px;
        height: 37px;
        cursor: pointer;

        &.icon-reset {
          background: url('/assets/icon-reset.png') 0 0 no-repeat;
        }

        &.icon-email {
          background: url('/assets/icon-email.png') 0 0 no-repeat;
        }

        &.icon-info {
          background: url('/assets/icon-info.png') 0 0 no-repeat;
        }

        &.icon-print {
          background: url('/assets/icon-print.png') 0 0 no-repeat;
        }
      }
    }
  }

  .content-container {
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding: 25px 25px 32px 25px;
    background: #f8f8f8 url('/assets/lines.png') 0 0 repeat-x;
    border-top: 1px solid #dfdfdf;
    border-bottom: 1px solid #dfdfdf;
    overflow-x: auto;

    @media print {
      background: none;
      padding: 0;
      overflow-x: hidden;
    }

    .reset-container {
      height: 24px;
      cursor: pointer;

      @media print {
        display: none;
      }
    }

    .split-container {
      display: flex;
      flex: auto;
      gap: 24px;

      .left-panel {
        display: flex;
        flex-direction: column;
        gap: 3px;
        width: 200px;
        min-width: 200px;

        @media print {
          display: none;
        }
      }

      .right-panel {
        display: flex;
        flex: auto;
        align-items: flex-start;

        .initial-screen {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;

          .error-message {
            display: flex;
            flex: auto;
            font-size: 1.2em;
            font-weight: bold;
            color: red;
            justify-content: center;
            margin-bottom: 24px;
          }

          .caption {
            line-height: 1.4em;
            margin-bottom: 24px;
          }

          .get-started {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 24px;
            margin-bottom: 48px;

            .large-button {
              display: inline-block;
              cursor: pointer;
              border: 4px solid #fff;
              border-radius: 8px;
              background: #218d3d;
              padding: 10px 24px;
              box-shadow: 0 0 6px #999999;
              color: #fff;
              font-size: 14px;
            }

            .or-image {
              width: 40px;
              height: 10px;
            }

            .select-label {
              color: #646464;
              font-size: 16px;
            }
          }

          .products-image {
            display: flex;
            width: 100%;
            max-width: 450px;
            max-height: 278px;
          }
        }
      }
    }
  }

  .footer {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 24px;
    padding: 18px 24px;

    @media print {
      display: none;
    }

    .footer-copy {
      line-height: 1.4em;
      color: #7e7e7e;
      font-size: 9px;

      div {
        white-space: nowrap;
      }
    }

    .footer-nav {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      row-gap: 6px;
      font-size: 11px;
      color: #0079c1;

      a, a:visited {
        white-space: nowrap;
        text-decoration: none;
        color: #67bdea;
        padding: 1px 3px;
        margin: 0 3px;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

}
