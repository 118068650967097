.table-products {
  table-layout: auto;
  width: 100%;
  font-size: 11px;
  border-collapse: collapse;
  border-top: 1px solid #606165;
  border-right: 1px solid #606165;
  border-bottom: none;
  border-left: 1px solid #606165;

  @media print {
    font-size: 0.7em;
  }

  th {
    color: #ffffff;
    background-color: #666666;
    border-right: 1px solid #ffffff;
    padding: 4px;
    font-weight: bold;
    white-space: nowrap;

    @media print {
      border-color: #666666;
    }

    &.wholesaler-number {
      white-space: break-spaces;
    }
  }

  td {
    text-align: center;
    border-right: 1px solid #606165;
    border-bottom: 1px solid #606165;
    padding: 4px;

    &.ndc-number,
    &.strength,
    &.concentration,
    &.wholesaler-number {
      white-space: nowrap;
    }
  }
}

.no-records-found {
  display: flex;
  flex: auto;
  height: 100%;
  max-height: 150px;
  font-weight: bold;
  color: red;
  justify-content: center;
}
